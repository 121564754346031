import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	activeSprintSelectionWarningMessage: {
		id: 'issue-create-commons.sprint-field.active-sprint-selection-warning-message',
		defaultMessage: "Creating this issue will affect the active sprint's scope",
		description: 'Warning message to be shown when active sprint is selected',
	},
	activeSprintSelectionWarningMessageIssueTermRefresh: {
		id: 'issue-create-commons.sprint-field.active-sprint-selection-warning-message-issue-term-refresh',
		defaultMessage: "Creating this issue will affect the active sprint's scope",
		description: 'Warning message to be shown when active sprint is selected',
	},
});
