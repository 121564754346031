import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	requiredErrorMessage: {
		id: 'issue-create-commons.common.field-message.required-error-message',
		defaultMessage: '{fieldName} is required',
		description: 'Error message for required fields',
	},
	labelsInvalidErrorMessage: {
		id: 'issue-create-commons.common.field-message.labels-invalid-error-message',
		defaultMessage:
			"Labels can't have spaces or be more than {MAX_LABEL_SIZE, plural, one {# character} other {# characters}}.",
		description: 'Validation error displayed if label input is invalid',
	},
	stringInvalidErrorMessage: {
		id: 'issue-create-commons.common.field-message.string-invalid-error-message',
		defaultMessage:
			"{fieldName} can't exceed {MAX_STRING_SIZE, plural, one {# character} other {# characters}}.",
		description: 'Validation error displayed if text input is invalid',
	},
	forgeLabelsInvalidErrorMessage: {
		id: 'issue-create-commons.common.field-message.forge-labels-invalid-error-message',
		defaultMessage:
			"Labels can't have more than {MAX_LABEL_SIZE, plural, one {# character} other {# characters}}.",
		description: 'Validation error displayed if label input is invalid',
	},
	labelsMaxErrorMessage: {
		id: 'issue-create-commons.common.field-message.labels-max-error-message',
		defaultMessage:
			"Can't have more than {MAX_ALLOWED_LABELS, plural, one {# label} other {# labels}}",
		description: 'Validation error displayed if the amount of labels exceeds the maximum',
	},
	fieldHelpTextLabel: {
		id: 'issue-create-commons.common.field-message.field-help-text-label',
		defaultMessage: 'Learn more',
		description: 'Help text for fields',
	},
	fieldHelpIssueTypes: {
		id: 'issue-create-commons.common.field-message.field-help-issue-types',
		defaultMessage: 'Learn about issue types',
		description: 'Helper text for issue types',
	},
	fieldHelpPriorityLevels: {
		id: 'issue-create-commons.common.field-message.field-help-priority-levels',
		defaultMessage: 'Learn about priority levels',
		description: 'Helper text for priority levels',
	},
	fieldHelpResolutionDefinitions: {
		id: 'issue-create-commons.common.field-message.field-help-resolution-definitions',
		defaultMessage: 'Resolutions definitions',
		description: 'Helper text for resolutions definitions',
	},
	fieldHelpSecurityLevels: {
		id: 'issue-create-commons.common.field-message.field-help-security-levels',
		defaultMessage: 'More on security levels',
		description: 'Help text for security levels',
	},
	fieldHelpIssueTypesIssueTermRefresh: {
		id: 'issue-create-commons.common.field-message.field-help-issue-types-issue-term-refresh',
		defaultMessage: 'Learn about issue types',
		description: 'Helper text for issue types',
	},
});
