import { ASC } from '@atlassian/jira-common-constants/src/sort-directions.tsx';

export const DEFAULT_RANK_SORTING_DIRECTION = ASC;
export const DEFAULT_AVATAR_RES = '48x48';

export const FILTER_PARAM = 'filter';
export const INLINE_CREATE_PARAM = 'inlineCreate';
export const INVITE_OPEN_PARAM = 'inviteOpen';
export const QUICK_FILTERS_PARAM = 'quickFilters';
export const SELECTED_ISSUE_PARAM = 'selectedIssue';
export const SELECTED_COMMENT_PARAM = 'selectedComment';

export const GROUP_BY_ASSIGNEE = 'assignee';
export const GROUP_BY_PRIORITY = 'priority';
export const GROUP_BY_CATEGORY = 'category';
export const GROUP_BY_STATUS = 'status';

export const START_DATE_ALIAS_FIELD_ID = 'startdate';
export const RANK_ALIAS_FIELD_ID = 'rank';
export const REMOTE_ISSUE_LINKS_TYPE = 'remoteIssueLinks';
export const COMMENT_SUMMARY_TYPE = 'commentsummary';
export const CARD_COVERS_TYPE = 'cardCovers';

export const DEFAULT_PAGE_SIZE = 25;

export const STORY_POINTS_ALIAS_FIELD_ID = 'storypoints';
export const STORY_POINT_ESTIMATE_ALIAS_FIELD_ID = 'storypointestimate';
export const SPRINT_ALIAS_FIELD_ID = 'sprint';

export const MAX_ISSUE_TYPES_COUNT = 25;
